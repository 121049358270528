<template>
  <div>

    <div class="d-flex justify-start align-center" style="height:50px;color: #d31145;background-color:#fff;padding: 0 20px 0 20px;">
      <v-icon color="#d31145" @click="$router.push('/master')">mdi-arrow-left</v-icon>
      <span style="padding-left:15px;font-weight:700">Toko</span>
    </div>

    <v-divider></v-divider>

    <div>
      <div v-if="list.data.length > 0 || searching != ''" class="pa-4" style="background-color:#ffffff;height:60px">
        <v-text-field
          dense
          v-model="searching"
          :hide-details="true"
          color="#ACACAC"
          filled
          rounded
          placeholder="Search..."
          elevation="0">
          <template v-slot:prepend-inner>
            <v-icon 
              color="#ACACAC">
              mdi-magnify
            </v-icon>
          </template>

          <template v-slot:append>
            <v-icon 
              color="#FF2353"  
              v-show="searching != ''"
              @click="
                searching = '';
                initialize()">
              mdi-close-circle
            </v-icon>
          </template>
        </v-text-field>
      </div>
      <v-card flat v-if="list.data.length < 1 && searching == '' && !process.run">
        <v-card-text style="padding: 20px 20px">
          <div style="font-weight:700;font-size:16pxline-height: 19px;padding-bottom: 5px">
            Anda belum menambahkan Toko
          </div>

          <div style="padding-top:20px">
            <v-btn rounded depressed to="/master/store/form" color="#d31145" elevation="0" class="white--text text-capitalize">
              <v-icon>mdi-plus</v-icon>
              Tambahkan
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
      <v-card flat v-else-if="list.data.length < 1 && searching != ''">
        <v-card-text
          class="d-flex align-center"
          style="height: calc(100vh - 200px); background: #f9f9f9; ">
            <v-img
              class="mx-auto"
              max-width="200"
              src="https://sodapos.com/media/2021/12/image/no-result-1638951068.png">
            </v-img>
        </v-card-text>
      </v-card>
      <v-card flat v-else>
        <v-card-text 
          class="px-0 pb-0 py-0" 
          style="
            overflow: auto;
            height: calc(100vh - 120px);
            background-color:#f9f9f9">

          <div v-if="list.data.length < 1 && searching == '' && process.run">
            <v-skeleton-loader
              v-for="item in 15" :key="item"
              type="list-item-avatar-two-line">
            </v-skeleton-loader>
          </div>

          <v-list subheader two-line class="py-0" dense>
            <template v-for="(item, i) in list.data">
              <v-list-item :key="item.id">
                <v-list-item-avatar tile size="50">
                  <v-img
                    width="50"
                    height="50"
                    class="rounded-lg"
                    :src="item.image_url">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center">
                        <v-progress-circular
                          indeterminate
                          color="#d31145">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-list-item-avatar>
                <v-list-item-content class="cursor-pointer" @click="getDetail(item)">
                  <v-list-item-title class="body-1 font-weight-bold">
                    {{ item.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ item.address }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <div>
                    <v-btn icon :to="`/master/store/form?id=${item.id}`">
                      <v-icon color="#d31145">mdi-pencil</v-icon>
                    </v-btn>
                    <!-- <v-btn icon @click="getDetail(item)">
                      <v-icon color="#d31145">mdi-delete</v-icon>
                    </v-btn> -->
                  </div>
                </v-list-item-action>
              </v-list-item>
              <v-divider :key="i" class="mx-auto"></v-divider>
            </template>
            
          </v-list>
        </v-card-text>
      </v-card>
    </div>
    
    <v-btn v-if="list.data.length > 0" to="/master/store/form" class="float white--text text-capitalize" rounded color="#d31145">
      <v-icon color="#fff" class="my-float" left>mdi-plus</v-icon>
      Tambahkan
    </v-btn>

    <v-dialog v-model="dialog.detail" persistent width="360">
      <v-card flat style="border-radius:15px">
        <v-card-title class="subtitle-1 text-capitalize font-weight-bold white--text justify-center py-2" style="background-color:#d31145">
          <div 
            style="
              flex: 1; 
              display: flex; 
              justify-content: center">
              {{ detail.name }}
          </div>
          <v-icon @click="dialog.detail = false" color="#fff">mdi-close-circle-outline</v-icon>
          
        </v-card-title>

        <v-card-text 
          class="text-center py-4">

          <v-img
            contain
            width="100"
            height="100"
            class="rounded-xl mx-auto mt-3"
            :src="detail.image_url">
          </v-img>

          <div style="font-size: 14px" class="pt-4">
            {{ detail.mobilephone }}
          </div>

          <div style="font-size: 14px">
            {{ detail.address }}
          </div>

          <div style="border: 1px dashed #FA91AD;width: 100%;" class="mt-7"></div>

          <!-- <table class="py-5 d-flex justify-center align-center px-2">
          </table> -->

          <v-simple-table class="py-5 d-flex justify-center align-center" dense>
            <template>
              <tbody>
                <tr>
                  <th class="pl-0 text-start" style="font-weight: 400">Diskon</th>
                  <td class="text-center text-second">:</td>
                  <td class="text-start text-second font-weight-bold">
                    <span v-if="detail.discount_type == 'nominal'">
                      {{ detail.discount_value | price }}
                    </span>
                    <span v-if="detail.discount_type == 'percent'">
                      {{ detail.discount_value | percent }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <th class="pl-0 text-start" style="font-weight: 400">Biaya Layanan</th>
                  <td class="text-center text-second">:</td>
                  <td class="text-start text-second font-weight-bold">
                    <span v-if="detail.service_type == 'nominal'">
                      {{ detail.service_value | price }}
                    </span>
                    <span v-if="detail.service_type == 'percent'">
                      {{ detail.service_value | percent }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <th class="pl-0 text-start" style="font-weight: 400">Pajak</th>
                  <td class="text-center text-second">:</td>
                  <td class="text-start text-second font-weight-bold">
                    <span v-if="detail.tax_type == 'nominal'">
                      {{ detail.tax_value | price }}
                    </span>
                    <span v-if="detail.tax_type == 'percent'">
                      {{ detail.tax_value | percent }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <th class="pl-0 text-start" style="font-weight: 400">Status Toko</th>
                  <td class="text-center text-second">:</td>
                  <td class="text-start text-second font-weight-bold">
                    {{ detail.active_bool == true ? 'Aktif' : 'Tidak aktif' }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

        </v-card-text>
      </v-card>
    </v-dialog>

    <v-bottom-sheet v-model="confirmation" width="100%">
      <v-sheet
        class="text-left rounded-t-xl"
        height="214px"
        width="100%">
        <div 
          class="pa-4"
          style="
            font-size: 18px;
            color: #d31145; 
            font-weight: bold;
            height:60px;">
          Hapus Toko
        </div>

        <v-divider></v-divider>

        <div class="d-flex flex-wrap align-content-center align-center" style="font-size:14px;padding:10px 20px 5px 20px;height:96px;">
          Apakah anda yakin ingin menghapus Toko &nbsp;
          <span class="font-weight-bold text-title text-capitalize"> 
            {{ detail.name }} 
          </span> &nbsp;
          ?
        </div>

        <div class="d-flex justify-star align-center px-4 pb-4" style="position:absolute;bottom:0;width:100%">
          <v-btn 
            @click="deleteData()" 
            style="flex:1" 
            :loading="process.run" 
            :disabled="process.run" 
            rounded
            elevation="0" 
            color="#d31145" 
            class="body-1 d-flex justify-start text-capitalize mr-2 white--text">
            <v-icon>mdi-check-circle-outline</v-icon>

            <span style="display:flex;justify-content:end;width:100%">
              Ya, Hapus Data
            </span>
          </v-btn>

          <v-btn
            rounded
            outlined
            style="flex: .5"
            color="#d31145"
            class="body-1 text-capitalize"
            :disabled="process.run"
            @click="confirmation = false">
            Batalkan
          </v-btn>
        </div>
      </v-sheet>
    </v-bottom-sheet>

  </div>
</template>

<script>
export default {
  data: () => ({
    list: {
      data: []
    },
    dialog: {
      store: {
        create: false
      },
      detail: false
    },
    confirmation: false,
    searching: "",
    pagination: {},
    detail: {},
    process: {
      run: false
    }
  }),
  components: {

  },
  watch: {
    searching: _.debounce(function(newVal) {
      this.searching = newVal;
      this.pagination.page = newVal === null ? "" : 1;

      this.initialize();
    }, 500),
  },
  computed: {

  },
  created() {

  },
  mounted(){
    this.initialize()
  },
  methods: {
    async initialize(){
      this.process.run = true
      let params = {
        search: this.searching,
        sort: "",
        dir: "",
        limit: 1000,
        page: 1
      }

      let res = await this.$get(`pos/store`, params)

      if (res.status == 200) {
        this.process.run = false
        this.list.data = res.results.data
        this.pagination = res.results.pagination
      }

      this.process.run = false
    },
    getDetail(item, status){
      this.detail = item
      if(status == 'delete') {
        this.confirmation = true
      }else{
        this.dialog.detail = true
      }
    },
    async deleteData(){
      this.$store.state.overlay.state = true
      this.confirmation = false
      this.process.run = true
      let data = {
        id: this.detail.id,
      }
      let res = await this.$post(`pos/store/delete`, data)

      if (res.status == 200) {
        this.process.run = false
        this.initialize()
        this.$store.state.overlay.state = false
        this.$store.state.snackbar.state = true
        this.$store.state.snackbar.content = res.message
        // localStorage.setItem('user', JSON.stringify(res.results.data))
      }

      this.process.run = false
      this.$store.state.overlay.state = false
    },

  },
}
</script>